<template>
  <div v-if="commonCodeByOrder && gameCount" class=""><!-- class="mainBg" -->
    <div><!-- class="mainBgdark" -->
      <section class="w100w">
        <!-- <div class="loading">
          <img src="@/assets/img/loading.gif" />
        </div> -->
        <transition name="fade">
          <div class="mainB">
            <ui-swiper />
          </div>
        </transition>
        <!-- <div class="mainB">
          <img src="../assets/img/slide1.png">
        </div> -->
        <!-- <div class="topbanner">
          <div class="topbannerName">
            공지사항
          </div>
          <div class="topbannerin">
             <span class="">{{$t('front.header.msg')}}</span>
          </div>
        </div> -->
      </section>
      <div class="tabWrap jack">
        <div class="jack-title">
          프로그레시브 JACKPOT
        </div>
        <DigitalCounter />
      </div>
      <!-- <div class="tabWrap">
        <div class="tab_01">
          <ul class="">
              <li class="casino" data-tab="tab-1" v-if="gameCount['casino']" @click="currentTab = 'tab-1'" :class="{'current': currentTab == 'tab-1'}">
                <h3>전세계 메이저 라이브 카지노</h3>
                <img src="@/assets/img/new/casino_txt.svg" />
                <a>Play</a>
              </li>
              <li class="" data-tab="tab-2" v-if="gameCount['hc-casino']" @click="currentTab = 'tab-2'" :class="{'current': currentTab == 'tab-2'}">
                <h3>전세계 메이저 호텔 카지노</h3>
                <img src="@/assets/img/new/casino_txt.svg" />
                <a>Play</a>
              </li>
              <li class="slot1" data-tab="tab-3" v-if="gameCount['slot']" @click="currentTab = 'tab-3'" :class="{'current': currentTab == 'tab-3'}">
                <h3>전세계 메이저 슬롯게임</h3>
                <img src="@/assets/img/new/slot_txt.svg" />
                <a>Play</a>
              </li>
              <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
                <li @click="onCasinoSelectGame(item.groupCode, item.code)">
                    <h3>지상최대의 스포츠게임</h3>
                    <img src="@/assets/img/new/sport_txt.svg" />
                    <a>Play</a>
                </li>
              </template>
              <li class="" data-tab="tab-6" v-if="gameCount['h-slot']" @click="currentTab = 'tab-6'" :class="{'current': currentTab == 'tab-6'}">
                <h3>전세계 메이저 호텔 슬롯게임</h3>
                <img src="@/assets/img/new/slot_txt.svg" />
                <a>Play</a>
              </li>
              <li class="" data-tab="tab-5" v-if="gameCount['mini-game']" @click="currentTab = 'tab-5'" :class="{'current': currentTab == 'tab-5'}">
                <h3>업계 최고배당 미니게임</h3>
                <img src="@/assets/img/new/mini_txt.svg" />
                <a>Play</a>
              </li>
          </ul>
        </div>
      </div> -->
      <section class="mt50 mb50 overflow-v">
        <ul class="mainGame">
          <li @click="onGameSlot">
            <div class="mg-img">
              <img src="../assets/img/buja/slot-bg.jpg">
            </div>
            <div class="mg-txt">
              <h4>슬롯게임 Slot</h4>
              <p>잭팟의 행운을 가지세요</p>
            </div>
            <img class="char" src="../assets/img/buja/slot-char.png">
          </li>
          <li @click="onGameCasino">
            <div class="mg-img">
              <img src="../assets/img/buja/casino-bg.jpg">
            </div>
            <div class="mg-txt">
              <h4>라이브카지노 Live</h4>
              <p>실시간으로 빅배팅을 즐기세요</p>
            </div>
            <img class="char" src="../assets/img/buja/casino-char.png">
          </li>
          <li @click="onGameSport">
            <div class="mg-img">
              <img src="../assets/img/buja/sports-bg.jpg">
            </div>
            <div class="mg-txt">
              <h4>스포츠배팅 Sports</h4>
              <p>해외실시간스포츠로 준비되었습니다</p>
            </div>
            <img class="char" src="../assets/img/buja/sports-char.png">
          </li>
        </ul>
      </section>
      <section class="mt50 mb50 overflow-v">
        <!-- <div class="tab-content">
          <h3 class="gameTitle">
            <img src="../assets/img/buja/live_tit.png">
          </h3>
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
              <li v-if="item.groupCodeName.indexOf('HC-') >= -1"
                  @click="onCasinoSelectGame(item.groupCode, item.code)"
                  class="gamecard_bg casino"
              >
                  <div :style="getBackgroundImage(item)" class="gameImg"></div>
                  <div class="startBtn"></div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
              </li>
            </template>
          </ul>
        </div>
        <div class="tab-content mt20">
          <h3 class="gameTitle">
            <img src="../assets/img/buja/slot_tit.png">
          </h3>
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
              <li v-if="item.groupCodeName.indexOf('H-') === -1"
                @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
                class="gamecard_bg slot"
              >
                  <div :style="getBackgroundImage(item)" class="gameImg"></div>
                  <div class="startBtn"></div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
              </li>
            </template>
          </ul>
        </div> -->
        <!-- <div id="tab-1" :key="'tab-1'" class="tab-content" v-if="currentTab == 'tab-1'">
          <h3 class="gameTitle">
            <img src="../assets/img/buja/live_tit.png">
          </h3>
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
              <li v-if="item.groupCodeName.indexOf('HC-') >= -1"
                  @click="onCasinoSelectGame(item.groupCode, item.code)"
                  class="gamecard_bg casino"
              >
                  <div :style="getBackgroundImage(item)" class="gameImg"></div>
                  <div class="startBtn"></div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
              </li>
            </template>
          </ul>
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
            </template>
          </ul>
        </div>

        <div id="tab-2" :key="'tab-2'" class="tab-content" v-if="currentTab == 'tab-2'">
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
              <li v-if="item.groupCodeName.indexOf('HC-') > -1"
                  @click="onCasinoSelectGame(item.groupCode, item.code)"
              >
                <div :style="getBackgroundImage(item)" class="gameImg">
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                </div>
                <p class="name">
                  <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                  <span class="gameNameEN">{{item.codeNameEN}}</span>
                  <button class="enterGame" @click="onCasinoSelectGame(item.groupCode, item.code)">게임입장</button>
                </p>
              </li>
            </template>
          </ul>
        </div>

        <div id="tab-3" :key="'tab-3'" class="tab-content" v-if="currentTab == 'tab-3'">
          <ul class="betGames betGamesb">
            <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
              <li v-if="item.groupCodeName.indexOf('H-') === -1"
                  @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
              >
                <div class="inner">
                    <img class="slot-bg" src="@/assets/img/game/slot-bg.png">
                    <div class="hover-bg">
                        <span></span><span></span><span></span><span></span>
                    </div>
                    <div class="slot-cont">
                      <img class="slot-img" :src="require('@/assets/img/game/game_main_pc_'+item.code+'_off.png')" />
                    </div>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div id="tab-6" :key="'tab-6'" class="tab-content" v-if="currentTab == 'tab-6'">
        <ul class="betGames betGamesb">
          <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                  v-if="item.groupCodeName.indexOf('H-') > -1"
                  @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
              >
                <div :style="getBackgroundImage(item)" class="gameImg">
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                </div>
                <p class="name">
                  <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                  <span class="gameNameEN">{{item.codeNameEN}}</span>
                  <button class="enterGame">게임입장</button>
                </p>
              </li>
            </template>
          </ul>
        </div>

        <div id="tab-4" :key="'tab-4'" class="tab-content" v-if="currentTab == 'tab-4'">
          <ul class="betGames betGamesa">
            <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
              <li
                  @click="onCasinoSelectGame(item.groupCode, item.code)"
                  @mouseover="item.isHover = true"
                  @mouseout="item.isHover = false"
              >
                <div :style="getBackgroundImage(item)" class="gameImg">
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                </div>
                <p class="name">
                  <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                  <span class="gameNameEN">{{item.codeNameEN}}</span>
                </p>
              </li>
            </template>

          </ul>
        </div>

        <div id="tab-5" :key="'tab-5'" class="tab-content" v-if="currentTab == 'tab-5'">
          <ul class="betGames betGamesa">

            <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
              <li
                  @click="onCasinoSelectGame(item.groupCode, item.code)"
                  @mouseover="item.isHover = true"
                  @mouseout="item.isHover = false"
              >
                <div :style="getBackgroundImage(item)" class="gameImg">
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                </div>
                <p class="name">
                  <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                  <span class="gameNameEN">{{item.codeNameEN}}</span>
                  <button class="enterGame" @click="onCasinoSelectGame(item.groupCode, item.code)">게임입장</button>
                </p>
              </li>
            </template>
          </ul>
        </div> -->

      </section>
      <section>
        <div class="servicecenter">
          <ul class="notiWrap">
            <li>
              <h4>
                <!-- <img src="@/assets/img/icon_notice_main.svg" /> -->
                {{ $t('front.common.notice') }}
              </h4>
              <a @click="goPageByName('notice')"><em>+</em>더보기</a>
            </li>
            <template v-for="item in noticeList" :key="item">
              <li @click="onClickNoticeRead(item)" class="notice">
                <span>{{item.title}}</span>
                <span>{{item.regDate}}</span>
              </li>
            </template>
            <template v-for="item in listMaxCount - noticeList.length" :key="item">
              <li>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </li>
            </template>
          </ul>
          <ul @click="goPageByName('charge')" class="inWrap">
            <li>
              <h4>
                <!-- <img src="@/assets/img/icon_event_main.svg" /> -->
                {{ $t('front.main.livecharge') }}
              </h4>
              <a><em>+</em>더보기</a>
            </li>
            <!-- <template v-for="item in cashInList" :key="item.memId">
              <li>
                <span>{{item.memId}}</span>
                <span><em class="c-rd">{{thousand(Number(item.cashAmt))}} 원</em>{{item.regDate}}</span>
              </li>
            </template>
            <template v-for="item in listMaxCount - cashInList.length" :key="item">
              <li>
                <span>&nbsp;</span>
                <span><em>&nbsp;</em>&nbsp;</span>
              </li>
            </template> -->
            <li>
              <span>tor****</span>
              <span><em>500,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>ioi****</span>
              <span><em>300,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>at0****</span>
              <span><em>1,000,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>rr33**</span>
              <span><em>300,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>ty3****</span>
              <span><em>490,000 원</em>{{ formattedDate }}</span>
            </li>
          </ul>
          <ul @click="goPageByName('exchange')" class="outWrap">
            <li>
              <h4>
                <!-- <img src="@/assets/img/icon_event_main.svg" /> -->
                {{ $t('front.main.liveExchange') }}
              </h4>
              <a><em>+</em>더보기</a>
            </li>
            <!-- <template v-for="item in cashOutList" :key="item.memId">
              <li>
                <span>{{item.memId}}</span>
                <span><em class="c-bl">{{thousand(Number(item.cashAmt*-1))}} 원</em>{{item.regDate}}</span>
              </li>
            </template>
            <template v-for="item in listMaxCount - cashOutList.length" :key="item">
              <li>
                <span>&nbsp;</span>
                <span><em>&nbsp;</em>&nbsp;</span>
              </li>
            </template> -->
            <li>
              <span>yu8****</span>
              <span><em>600,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>kor****</span>
              <span><em>3,000,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>vip7**</span>
              <span><em>2,200,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>ress***</span>
              <span><em>1,000,000 원</em>{{ formattedDate }}</span>
            </li>
            <li>
              <span>c588*****</span>
              <span><em>850,000 원</em>{{ formattedDate }}</span>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <ul class="sns" v-if="siteOption"><!-- v-if="siteOption" -->
           <li @click="goPageByName('csCenter')">
              <img src="@/assets/img/icon_faq_main.svg" />
              <span>궁금한 점이 있으신가요?<em>고객센터가기</em></span>
           </li>
           <li>
             <img src="@/assets/img/telegram.svg" />
             <span>24시간 고객센터<em>{{siteOption['sns2']}} : {{siteOption['sns2Id']}}</em></span>
           </li>
           <li>
             <img src="@/assets/img/kakao.svg" />
             <span>24시간 고객센터<em>{{siteOption['sns1']}} : {{siteOption['sns1Id']}}</em></span>
           </li>
           <!--li>
             <img src="@/assets/img/zalo.svg" />
             <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
           </li-->
         </ul>
        <!-- <div class="main_bottom">
          <img src="../assets/img/buja/partner.png">
          <span class="tele">{{siteOption['sns2Id']}}</span>
        </div> -->
      </section>
      <div class="mainPopWrap">
        <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
          <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
            <div class="closeWrap">
              <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
              <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
            </div>
            <img :src="item.bannerImg" />
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="game-modal" v-if="gameModal">
    <div class="modal-con">
      <div class="offBtnWrap">
        <div @click="offGame" class="offBtn">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="game-tab">
        <ul>
          <li @click="onSlot" :class="{'active' : currentGame === 'slot'}">슬롯게임</li>
          <li @click="onCasino" :class="{'active' : currentGame === 'casino'}">온라인 카지노</li>
          <li @click="onSport" :class="{'active' : currentGame === 'sport'}">스포츠</li>
        </ul>
      </div>
      <div class="game-list" v-if="currentGame === 'casino'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
            <li v-if="item.groupCodeName.indexOf('HC-') >= -1"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                class="gamecard_bg casino"
            >
                <div :style="getBackgroundImage(item)" class="gameImg"></div>
                <div class="startBtn"></div>
                <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
            </li>
          </template>
        </ul>
      </div>
      <div class="game-list" v-if="currentGame === 'slot'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
            <li v-if="item.groupCodeName.indexOf('H-') === -1"
              @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
              class="gamecard_bg slot"
            >
                <div :style="getBackgroundImage(item)" class="gameImg"></div>
                <div class="startBtn"></div>
                <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
            </li>
          </template>
        </ul>
      </div>
      <div class="game-list" v-if="currentGame === 'sport'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
            <li
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <div :style="getBackgroundImage(item)" class="gameImg">
                <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
              </div>
              <p class="name">
                <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
                <span class="gameNameEN">{{item.codeNameEN}}</span>
              </p>
            </li>
          </template>

        </ul>
      </div>
    </div>
  </div>
   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import DigitalCounter from '@/components/ui//DigitalCounter.vue'
import store from '@/store'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper,
    DigitalCounter
  },
  async created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    await this.loadCashStatus()
  },
  data () {
    return {
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      noticeListMaxCount: 5,
      swiperView: false,
      todayDate: '',
      gameModal: false,
      currentGame: 'casino'
    }
  },
  watch: {
    gameCount () {
      if (this.gameCount && this.commonCodeByOrder && !this.swiperView) {
        this.loadSwiper()
      }
    },
    commonCodeByOrder () {
      if (this.gameCount && this.commonCodeByOrder && !this.swiperView) {
        this.loadSwiper()
      }
    },
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  mounted () {
    this.setToday()
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'gameCount',
      'siteOption'
    ]),
    formattedDate () {
      const year = this.todayDate.getFullYear()
      const month = (this.todayDate.getMonth() + 1).toString().padStart(2, '0')
      const day = this.todayDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

  },
  methods: {
    onGameSlot () {
      this.gameModal = true
      this.currentGame = 'slot'
    },
    onGameCasino () {
      this.gameModal = true
      this.currentGame = 'casino'
    },
    onGameSport () {
      this.gameModal = true
      this.currentGame = 'sport'
    },
    onCasino () {
      this.currentGame = 'casino'
    },
    onSlot () {
      this.currentGame = 'slot'
    },
    onSport () {
      this.currentGame = 'sport'
    },
    offGame () {
      this.gameModal = false
    },
    setToday () {
      this.todayDate = new Date()
    },
    scroll_left () {
      const content = document.querySelector('.boxa')
      content.scrollLeft -= 720
    },
    scroll_right () {
      const content = document.querySelector('.boxa')
      content.scrollLeft += 720
    },
    scroll_lefta () {
      const content = document.querySelector('.boxb')
      content.scrollLeft -= 720
    },
    scroll_righta () {
      const content = document.querySelector('.boxb')
      content.scrollLeft += 720
    },
    scroll_leftb () {
      const content = document.querySelector('.boxc')
      content.scrollLeft -= 720
    },
    scroll_rightb () {
      const content = document.querySelector('.boxc')
      content.scrollLeft += 720
    },
    scroll_leftc () {
      const content = document.querySelector('.boxd')
      content.scrollLeft -= 720
    },
    scroll_rightc () {
      const content = document.querySelector('.boxd')
      content.scrollLeft += 720
    },
    scroll_leftd () {
      const content = document.querySelector('.boxe')
      content.scrollLeft -= 720
    },
    scroll_rightd () {
      const content = document.querySelector('.boxe')
      content.scrollLeft += 720
    },
    loadSwiper () {
      setTimeout(() => {
        this.gameSwiper()
        this.gameSwipera()
        this.gameSwiperb()
        this.swiperView = true
      }, 0)
    },
    gameSwiper () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.gameSlide', {
        spaceBetween: 20,
        slidesPerView: 5,
        centeredSlides: false,
        slidesPerGroupSkip: 5,
        slidesPerGroup: 5,
        grabCursor: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.next1',
          prevEl: '.prev1'
        }
      })
    },
    gameSwipera () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.gameSlidea', {
        spaceBetween: 20,
        slidesPerView: 5,
        centeredSlides: false,
        slidesPerGroupSkip: 5,
        slidesPerGroup: 5,
        grabCursor: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.next2',
          prevEl: '.prev2'
        }
      })
    },
    gameSwiperb () {
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.gameSlideb', {
        spaceBetween: 20,
        slidesPerView: 5,
        centeredSlides: false,
        slidesPerGroupSkip: 5,
        slidesPerGroup: 5,
        grabCursor: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.next3',
          prevEl: '.prev3'
        }
      })
    },
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    async loadCashStatus () {
      await getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/game/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
<style scoped src="@/styles/swiper.min.css"></style>
<style scoped>
.mt20{margin-top: 20px;}
.mt111 {height: 400px}
.loading {z-index: 10;position: absolute;top: 0;width: 100%;height: 310px;padding-top: 40px;display: flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
h2.mainnames {width: 1220px;margin: 0 auto;padding: 30px 0 20px;font-size: 30px;font-weight: bold;color: #000;}
ul.tabs {display: flex;justify-content: center;align-items: center;padding: 30px 0;width: 1220px;margin: 0 auto;gap: 5px;}
ul.tabs li {display: flex;align-items: center;justify-content: center;font-size: 14px;color: #fff;cursor: pointer;width: 148px;height: 30px;border: solid 1px #777;background: #111;}
.tab-content{width: 1260px;}
.tab-content.current{display: inherit;}
ul.tabs li:hover {border: 1px solid #e5972d;background: #333;}
ul.tabs li.current {color: #000;border: 1px solid #e5972d;background: #e5972d;}

.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #ededed;border: 1px solid #707070;border-radius: 30px;position: absolute;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 1;width: 1314px;}
.slotmodal>h3 {width: 100%;position: relative;text-align: center;font-size: 30px;font-weight: bold;color: #fff;padding: 15px 0;background: #222;border-radius: 30px 30px 0 0;}
.slotmodal>h3::after {content: '';display: block;width: 100%;height: 17px;background-image: linear-gradient(to right, #319e8a, #1c594e);position: absolute;bottom: -25px;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 9px;height: 40px;}
.slotmodalwrap {max-height: 900px;overflow-y: auto;padding-top: 55px;}
.slotmodalwrap ul {padding: 0 65px 30px;display: flex;gap: 25px;justify-content: center;}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width: 100%;border-radius: 12px;border: 2px solid #319e8a;height: 200px;}

.mainPopWrap {display: flex;align-items: center;gap: 50px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100;}
.mainPop {position: relative;min-width: 320px;background-color: #383838;border-radius: 15px;padding: 15px;display: flex;flex-direction: column-reverse;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;padding: 15px;color: #fff;font-size: 12px;border-top: 1px solid #ffffff40;}
.mainPop a.close {color: #fff;background: #069;border: 1px solid #0cf;border-radius: 5px;height: 36px;width: 100px;display: flex;align-items: center;justify-content: center;box-sizing: border-box;}
.mainPop>img {width: 430px;height: 640px;vertical-align: bottom;object-fit: cover;object-position: center;box-shadow: 3px 3px 10px rgba(0,0,0,0.5);border-radius: 15px;margin-bottom: 15px;}
</style>
